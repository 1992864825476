import React, { useEffect, useState, useRef, useContext } from 'react';
import { Form } from '@unform/web';
import Input from '../../components/Form/input';
import moment from 'moment';
import { MainContext } from '../../contexts/mainContext';
import { auth } from 'services/firebase';

import { Spacer, Error, Loading, Text, NoConnection, Modal } from 'components';
import { Container, InputContainer, BoxContainer, FlexContainer } from './styles';
import PoHeader from 'compositions/PoHeader';
import PoQuantity from 'compositions/PoQuantity';
import SectionHeader from 'compositions/SectionHeader';

import Tag from './components/Tag';

import { removeInfo, setBuffer } from '../../utils/storage';
import { NotificationError, NotificationSuccess, NotificationWarning } from '../../utils/toast';
import { getStorageBoxes, barcodeSeparator, validateTag, isInvalidNumber } from '../../utils/helpers';
import { GetOrderInfo } from '../../graphql/subscriptions/subscriptions.graphql';
import { GetOrderInfo2, GetTotalBoxes, CurrentShift } from '../../graphql/queries/queries.graphql';
import { SET_BOX, SET_MULTIPLE_BOXES } from '../../graphql/mutations';
import { useSubscription, useLazyQuery, useMutation } from '@apollo/react-hooks';
import ScannerDetector from 'js-scanner-detection';
import History from './components/History';


function AppContainer(props) {
  const { idEquip } = props;
  let id = Number(idEquip);
  const formRef = useRef(null);
  const formTagRef = useRef(null);
  const [tagData, setTagData] = useState({ barcode: '12315;0;4546' });
  const [tagModal, setTagModal] = useState(false);
  const [boxes, setBox] = useState(() => {
    let tags = localStorage.getItem('tags');
    try {
      let tagsParsed = JSON.parse(tags);
      return tagsParsed;
    } catch (error) {
      console.error('Error parsing tags');
    }
    return [];
  });
  const [updateModal, setUpdateModal] = useState(false);
  const { state, dispatch } = useContext(MainContext);
  const { currentOrder: order, isOffline, userName, id_enterprise, equipment } = state;

  const [
    GetInitialOrderInfo,
    {
      data: currentOrderData,
      loading: currentOrderLoading,
    }
  ] = useLazyQuery(GetOrderInfo2, {
    variables: { id_equipment: id },
    onCompleted: (data) => {
      if (data?.v_current_production_orders?.length) {
        dispatch({ type: 'CURRENT_ORDER', payload: data.v_current_production_orders });
        getTotalBoxes({
          variables: {
            id_equipment: id,
            id_production_order: data.v_current_production_orders[0].id_production_order
          }
        });
      }
    }
  });

  const [getTotalBoxes, { data: totalBoxData }] = useLazyQuery(GetTotalBoxes, {
    onCompleted: (data) => {
      localStorage.setItem('tags', JSON.stringify(data?.v_scanned_boxes));
      setBox(data?.v_scanned_boxes);
    }
  });
  const [getShift, { data: shiftData }] = useLazyQuery(CurrentShift, {
    variables: { id_equipment: id },
    onCompleted: () => {
      if (isOffline) dispatch({ type: 'NETWORK_STATUS', payload: false });
    },
    onError: (error) => {
      if (error.networkError) dispatch({ type: 'NETWORK_STATUS', payload: true });
    }
  });

  useSubscription(GetOrderInfo, {
    variables: { id_equipment: id },
    onSubscriptionData: (data) => {
      if (data?.subscriptionData?.data?.v_current_production_orders?.length) {
        dispatch({ type: 'CURRENT_ORDER', payload: data.subscriptionData.data.v_current_production_orders });
        if (isOffline) dispatch({ type: 'NETWORK_STATUS', payload: false });
      }
    }
  });

  const [setScannedBox] = useMutation(SET_BOX, {
    onCompleted: () => {
      getTotalBoxes({
        variables: {
          id_equipment: id,
          id_production_order: order[0].id_production_order
        }
      });
    },
    onError: (error) => {
      if (error.networkError) {
        dispatch({ type: 'NETWORK_STATUS', payload: true });
        NotificationError('Erro de conexão! As próximas caixas serão armazenadas para envio posterior.');
      } else {
        if (error?.graphQLErrors[0]?.extensions?.code === 'constraint-violation') {
          NotificationError('Não foi possível o envio! Caixa duplicada.');
        }
        if (error?.graphQLErrors[0]?.extensions?.code === 'data-exception') {
          NotificationError('Dados incorretos! Revise a etiqueta.');
        } else {
          NotificationError('Ocorreu um erro no servidor!');
        }

      }
    }
  });

  const [setMultipleBoxes,
    {
      loading: multiBoxesLoading
    }] = useMutation(SET_MULTIPLE_BOXES, {
      onCompleted: () => {
        setBuffer([]);
        getTotalBoxes({
          variables: {
            id_equipment: id,
            id_production_order: order[0].id_production_order
          }
        });
        NotificationSuccess('Dados enviados!');
      }, onError: (error) => {
        if (error?.graphQLErrors[0]?.extensions?.code === 'constraint-violation') {
          NotificationError('Não foi possível o envio! Caixas duplicadas.');
        } else {
          NotificationError('Ocorreu um erro no servidor!');
        }
        setBuffer([]);
      }
    });

  useEffect(() => {
    if (!id_enterprise) {
      auth().signOut();
      removeInfo();
    } else {
      GetInitialOrderInfo();
      getShift();
    }

    if (localStorage.getItem('@barcode:update') === 'true') setUpdateModal(true);

    const options = {
      onComplete: (barcode) => {
        formRef.current.setFieldValue('barcode', barcode.toLowerCase());
        formRef.current.submitForm();
        //handleScan(barcode);
      }
    };

    const scannerDetector = new ScannerDetector(options);

  }, []);

  useEffect(() => {
    if (!isOffline) {
      let storageBoxes = getStorageBoxes();
      let allBoxData = [];
      if (storageBoxes) {
        if (storageBoxes?.length) {
          for (let i in storageBoxes) {
            const separator = barcodeSeparator(storageBoxes[i].barcode);
            let boxArrayData = storageBoxes[i].barcode.split(separator);
            allBoxData.push(
              {
                box_order_number: boxArrayData[1],
                increment: boxArrayData[2],
                id_enterprise,
                id_equipment: id,
                id_order: boxArrayData[0],
                id_production_order: storageBoxes[i].id_production_order,
                id_site: id_enterprise,
                ts_value: storageBoxes[i].timestamp
              }
            );
          }
          setMultipleBoxes({ variables: { objects: allBoxData } });

        }
      }
    }

  }, [isOffline]);

  useEffect(() => {
    if (tagData.number) {
      window.print();
    }
  }, [tagData]);

  function formatJob(job) {
    let idOrder = String(job);

    if (idOrder?.length <= 5) {
      return `0${job}`;
    } else {
      return job;
    }
  }

  const sendData = (data) => {
    data.barcode = data.barcode.toLowerCase();
    const separator = barcodeSeparator(data.barcode);
    const barcodeSplited = data.barcode.split(separator);
    let currentNumber = Number(barcodeSplited[1]);
    let boxNumbers = boxes?.map((item) => item.box_order_number);
    let boxMaxValue = Math.max(...boxNumbers);
    let diff = currentNumber - boxMaxValue;

    if (isInvalidNumber(diff)) {
      diff = 0;
    }
    if (diff >= 4) {
      NotificationError(
        `${diff} etiquetas não foram encontradas. Revise o número da última etiqueta para prosseguir`
      );
      return;
    }

    if (validateTag(data.barcode, boxes, isOffline, order)) {
      if (Number(barcodeSplited[1]) !== 0) {
        setScannedBox({
          variables: {
            box: barcodeSplited[1],
            qtd: barcodeSplited[2],
            id_enterprise,
            id_equip: id,
            id_order: order[0].id_order,
            id_prod_order: order[0].id_production_order,
            site: id_enterprise,
            ts: moment().format()
          }
        }
        );
      }

      let number = barcodeSplited[1];
      const printData = {
        number: `${Number(number) + 1}`,
        client: order[0]?.nm_client,
        size: `${order[0]?.diameter} x ${order[0]?.length}`,
        due_date: order[0]?.due_date,
        product: order[0]?.txt_product,
        codProd: order[0]?.label_product_id,
        codEsp: order[0]?.specification_id,
        orderNum: order[0]?.order_number,
        job: formatJob(order[0]?.id_order),
        boxQty: order[0].box_quantity,
        barcode: `${order[0]?.id_order};${Number(number) + 1};${order[0].box_quantity}`,
        hour: moment().format('HH:mm:ss'),
        date: moment().format('DD/MM/YYYY'),
        line: equipment?.name
      };
      setTagData(printData);
    }
  };

  function saveOnBuffer(data) {
    data.barcode = data.barcode.toLowerCase();
    data.id_production_order = order[0]?.id_production_order;
    const separator = barcodeSeparator(data.barcode);
    const barcodeSplited = data.barcode.split(separator);
    if (barcodeSplited[1] === '0') return;
    let storageBoxes = getStorageBoxes();
    if (validateTag(data.barcode, boxes, isOffline, order)) {
      if (storageBoxes && storageBoxes.length) {
        storageBoxes.push(data);
        setBuffer(storageBoxes);
      } else {
        setBuffer([data]);
      }
      let number = barcodeSplited[1];
      const printData = {
        number: `${Number(number) + 1}`,
        client: order[0]?.nm_client,
        size: `${order[0]?.diameter} x ${order[0]?.length}`,
        due_date: order[0]?.due_date,
        product: order[0]?.txt_product,
        codProd: order[0]?.label_product_id,
        codEsp: order[0]?.specification_id,
        orderNum: order[0]?.order_number,
        job: formatJob(order[0]?.id_order),
        boxQty: order[0].box_quantity,
        barcode: `${order[0]?.id_order};${Number(number) + 1};${order[0].box_quantity}`,
        hour: moment().format('HH:mm:ss'),
        date: moment().format('DD/MM/YYYY'),
        line: equipment?.name
      };
      setTagData(printData);
    }
  }

  function handleSubmit() {
    const data = formRef.current.getData();

    data.timestamp = moment().format();
    try {
      data.shift = shiftData.agg_eqvalues_shift[0].cd_shift;
    } catch (error) {
      data.shift = '';
    }

    if (!isOffline) {
      sendData(data);
    } else {
      if (validateTag(data.barcode, boxes, isOffline, order)) {
        saveOnBuffer(data);
      }
    }
  }

  function handlePrintTag() {
    const data = formTagRef.current.getData();

    setTagModal(false);
    const barcodeData = `${order[0]?.id_order};${data.number};${order[0].box_quantity}`;
    const printData = {
      number: `${data.number}`,
      client: order[0]?.nm_client,
      size: `${order[0]?.diameter} x ${order[0]?.length}`,
      due_date: order[0]?.due_date,
      product: order[0]?.txt_product,
      codProd: order[0]?.label_product_id,
      codEsp: order[0]?.specification_id,
      orderNum: order[0]?.order_number,
      job: formatJob(order[0]?.id_order),
      boxQty: order[0].box_quantity,
      barcode: barcodeData,
      hour: moment().format('HH:mm:ss'),
      date: moment().format('DD/MM/YYYY'),
      line: equipment?.name
    };
    setTagData(printData);
    formTagRef.current.reset();
  }

  if (currentOrderData) {
    if (!currentOrderData.v_current_production_orders.length) {
      return <Error error="Nenhuma ordem de produção encontrada x_x" />;
    }
  }

  if (currentOrderLoading || multiBoxesLoading) return <Loading />;
  let totalBoxesQuantity = totalBoxData?.scanned_boxes_aggregate?.aggregate?.sum?.increment;
  if (!totalBoxesQuantity) totalBoxesQuantity = 0;

  return (
    <Container>
      <NoConnection />
      <PoHeader operator={userName} order={order} />
      <Spacer isVertical size={5} />

      <BoxContainer>
        <Text>Número da ultima caixa: <b> {totalBoxData?.v_scanned_boxes?.[0]?.box_order_number || ''} </b></Text>
        <Text>Quantidade de caixas: <b>{totalBoxData?.v_scanned_boxes?.length || ''}</b></Text>
      </BoxContainer>

      <SectionHeader icon="ChartBars" title="Quantidade" />
      <PoQuantity boxQuantity={totalBoxesQuantity} order={order} />

      <Spacer isVertical size={20} />

      <div style={{ width: '100%', height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
        <button className="tag-btn" onClick={() => setTagModal(true)}>Gerar Etiqueta</button>
      </div>

      {/* Manual input   */}
      <InputContainer>
        <Form className="barcode-form" ref={formRef} onSubmit={handleSubmit}>
          <Input className="barcode-input" name="barcode" placeholder="Faça leitura do código de barras ou digite" type="text" />
          <button className="barcode-btn" type="submit">Confirmar</button>
        </Form>
      </InputContainer>

      {/* Generate tag   */}
      <Modal isOpen={tagModal} onClickToClose={() => setTagModal(false)}>
        <FlexContainer size={60} direction="column">
          <p style={{ fontWeight: 'bold', fontSize: 22 }}>Gerar Etiqueta</p>
        </FlexContainer>

        <Form className="tag-form" ref={formTagRef}>
          <Input className="tag-input" name="number" placeholder="Número da caixa" type="text" />
        </Form>
        <FlexContainer size={60} direction="row">
          <button className="cancel-btn" onClick={() => setTagModal(false)}>Cancelar</button>
          <button className="barcode-btn" onClick={handlePrintTag}>Imprimir</button>
        </FlexContainer>

      </Modal>

      {/* Refresh to update   */}
      <Modal isOpen={updateModal} onClickToClose={() => setUpdateModal(false)}>
        <FlexContainer size={140} direction="column">
          <p style={{ fontWeight: 'bold', fontSize: 22 }}>Nova atualização disponível!&nbsp;&nbsp;</p><br />
        </FlexContainer>

        <FlexContainer size={60} direction="row">
          <button className="cancel-btn" onClick={() => setUpdateModal(false)}>Fechar</button>
          <button className="barcode-btn" onClick={() => {
            /* localStorage.clear();
            auth().signOut(); */
            window.location.reload();
          }}>
            Atualizar
          </button>
        </FlexContainer>

      </Modal>

      <History boxData={totalBoxData} />
      <Tag tagData={tagData} />

    </Container>
  );
}

export default AppContainer;