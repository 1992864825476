import { css, createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import colors, { convertHexToRGB } from './colors';

export const GlobalStyles = createGlobalStyle`
  html, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  * { box-sizing: border-box; outline: 0; }
  *[hidden] { display: none; }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  html, #root {
    height: 100%;
  }
  ${normalize}
  body {
    height: 100%;
    overflow: hidden;
    line-height: 1;
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
    color: #333;
  }
  .login-btn {
    width: 90%;
    height: 40px;
    background: #399fef;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 3px;
    font-size: 1.25rem;
  }
  .recover-pass {
    background: #fff;
    border: none;
    color: ${colors.blue};
    cursor: pointer;
  }
  button {
    cursor: pointer;
  }
  .text-area {
    outline: none;
    margin-top: 20px;
    width: 100%;
    height: 100px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid #BDBDBD;
    background: #fff;
    color: #828282;
    resize: none;
    overflow: hidden;
    &:focus {
      border-bottom: 2px solid #2F80ED;
    }
  }
  form {
    width: 95%;
  }
  .barcode-form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
  }
  .barcode-input {
    width: 500px;
    height: 42px;
    border: 2px solid #b9b9b9;
    display: flex;
    align-self: center;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 10px;
    margin-right: 10px;

  }
  .barcode-btn {
    width: 150px;
    height: 42px;
    border: none;
    text-transform: uppercase;
    color: #fff;
    background: #327FF5;
    border-radius: 4px;
    font-weight: 700;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
    border-width: 0;
    outline: none;
  }
  .tag-form {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }
  
  .tag-input {
    width: 300px;
    height: 42px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    align-self: center;
    padding: 10px;
    margin-top: 4px;
    margin-bottom: 20px;

  }
  .tag-btn {
    width: 200px;
    height: 42px;
    border: none;
    color: #fff;
    background: #327FF5;
    border-radius: 4px;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
    border-width: 0;
    outline: none;
  }
  .cancel-btn {
    width: 150px;
    height: 42px;
    border: none;
    color: #000;
    background: ${colors.light};
    border-radius: 4px;
    margin-right: 5px;
    border-width: 0;
    outline: none;
    text-transform: uppercase;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    borderRadius: 100px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #bdbdbd;
  }
  
`;

export const smallFontSize = 15;
export const baseFontSize = 19;
export const mediumFontSize = 23;
export const largeFontSize = 35;

export const smallLineHeight = 1.2;
export const baseLineHeight = 1.5;

export const borderWidth = 1;
export const borderRadius = 2;

export const inputStyles = css`
  border-radius: 2px;
  border: 1px solid transparent;
  color: ${colors.dark};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: ${({ small }) => small ? 13 : 15}px;
  justify-content: flex-start;
  ${() => inputFocus}
  ${() => inputHover}
  ${() => inputPlaceholder}
  ${() => inputDisabled}
`;

export const inputFocus = css`&:focus {
  outline: none;
  border: 1px solid ${convertHexToRGB(colors.primary, .8)};
  box-shadow: 0 0 3px 2px ${convertHexToRGB(colors.secondary, .6)};
}`;

export const inputHover = css`&:hover {
  border: 1px solid ${convertHexToRGB(colors.primary, .8)};
}`;

export const elevation = css`box-shadow: 2px 2px 6px ${convertHexToRGB(colors.black, .3)};`;

export const inputReset = css`
  ${() => elementReset}
  font-size: ${baseFontSize}px;
`;

export const buttonReset = css`
  ${() => elementReset}
  cursor: pointer;
  line-height: 0;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    min-width: 40px;
    min-height: 40px;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const elementReset = css`
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  display: inline;
  line-height: 1.2;
  margin: 0;
  outline: none;
  padding: 0;
`;

export const inputDisabled = css`
  &:disabled {
    background-color: ${colors.grayLight};
    border-color: ${colors.disabled};
  }
`;

export const absoluteFill = css`
  flex: 1;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const truncate = css`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const truncateReverse = css`
  direction: rtl;
  max-width: 100%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const inputPlaceholder = css`
  &::-moz-placeholder { color: ${colors.dark}; }
  &::-webkit-input-placeholder { color: ${colors.dark}; }
  &:-moz-placeholder { color: ${colors.dark}; }
  &:-ms-input-placeholder { color: ${colors.dark}; }
`;
