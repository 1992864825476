/* eslint-disable max-len */
import gql from 'graphql-tag';

export const SET_BOX = gql`
    mutation SetBox($box: Int, $id_enterprise: bigint, $id_equip: bigint, 
    $id_order: bigint, $id_prod_order: bigint, $site: bigint, $ts: timestamptz, $qtd: Int) {
    insert_scanned_boxes(objects: {
      box_order_number: $box, 
      increment: $qtd,
      id_enterprise: $id_enterprise, 
      id_equipment: $id_equip, 
      id_order: $id_order, 
      id_production_order: $id_prod_order, 
      id_site: $site, 
      ts_value: $ts
      }) {
    affected_rows
  }
}

`;

export const SET_MULTIPLE_BOXES = gql`
  mutation insert_multiple_boxes($objects: [scanned_boxes_insert_input!]! ) {
  insert_scanned_boxes(objects: $objects) {
    returning {
      box_order_number
    } 
  }
}
`;