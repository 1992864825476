import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { barcodeSeparator, getMissingBoxes, getStorageBoxes } from '../../../utils/helpers';
import Tooltip from '../../../components/Tooltip';

const Container = styled.div`
  width: ${(props) => props.size}%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 15px;

  table>thead>tr>td {
    font-weight: bold;
    font-size: 16px;
  }
  table>thead {
    height: 32px;
    border-bottom: 1px solid #747474;
  }

  table>tbody>tr {
    height: 40px;
    border-bottom: 1px solid #b9b9b9;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #4D4D4D;
    margin-bottom: 15px;
  }

`;

const HistoryContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 500px;
  overflow: auto;
`;

const Status = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;


function History({ boxData }) {
  let lastBox = null;
  let totalBoxes = null;
  let totalBoxesQuantity = 0;
  let historyBoxes = null;
  let missing = null;

  if (boxData) {
    let data = null;
    if (boxData) {
      data = boxData.v_scanned_boxes;
    }
    if (data?.length) {
      lastBox = data[0]?.box_order_number;
      historyBoxes = data;
      let newBoxes = [...data];
      let storageBoxes = getStorageBoxes();
      if (storageBoxes) {
        for (let i in storageBoxes) {
          const separator = barcodeSeparator(storageBoxes[i].barcode);
          let boxArrayData = storageBoxes[i].barcode.split(separator);
          newBoxes.push({ box_order_number: Number(boxArrayData[1]) });
        }
      }
      let numbers = newBoxes.map((item) => item.box_order_number);
      missing = getMissingBoxes(numbers);
    } else {
      lastBox = null;
    }
    totalBoxesQuantity = data?.scanned_boxes_aggregate?.aggregate?.sum?.increment;
    if (!totalBoxesQuantity) totalBoxesQuantity = 0;
    totalBoxes = data?.length;
  }

  let storageBoxes = getStorageBoxes();
  let allBoxData = [];
  const initialDate = moment().subtract(24, 'hours').format();
  let boxesFiltered = [];
  if (storageBoxes) {
    if (storageBoxes.length) {
      for (let i in storageBoxes) {
        const separator = barcodeSeparator(storageBoxes[i].barcode);
        let boxArrayData = storageBoxes[i].barcode.split(separator);
        allBoxData.push(
          {
            box_order_number: Number(boxArrayData[1]),
            ts_value: storageBoxes[i].timestamp,
            id_order: Number(boxArrayData[0]),
            cd_shift: storageBoxes[i].shift
          }
        );
      }
    }
    allBoxData.sort((a, b) => b.box_order_number - a.box_order_number);
    if (allBoxData?.length > 21) allBoxData = allBoxData.slice(0, 20);
  }
  if (historyBoxes && historyBoxes.length) {
    boxesFiltered = historyBoxes.filter((item) => moment(item.ts_value).isSameOrAfter(initialDate));
    if (boxesFiltered?.length > 21) boxesFiltered = boxesFiltered.slice(0, 20);
  }

  let showMissingBoxes = [];
  if (missing?.length > 21) {
    showMissingBoxes = missing.slice(0, 20);
  } else {
    showMissingBoxes = missing;
  }

  return (
    <HistoryContainer>
      <Container size={70}>
        <p>Caixas lidas</p>
        <table>

          <thead>
            <tr>
              <td>Número</td>
              <td>OP</td>
              <td>Turno</td>
              <td>Horário</td>
              <td>Status</td>
            </tr>
          </thead>

          <tbody>
            {
              allBoxData && allBoxData.map((item) => (
                <tr key={item.box_order_number}>
                  <td>{item.box_order_number}</td>
                  <td>{item.id_order}</td>
                  <td>{item.cd_shift}</td>
                  <td>{moment(item.ts_value).format('HH:mm:ss DD/MM/YYYY')}</td>
                  <td>
                    <Tooltip text={'Pendente'}>
                      <Status color='#FD9729' />
                    </Tooltip>
                  </td>
                </tr>
              ))
            }
            {
              boxesFiltered && boxesFiltered.map((item) => (
                <tr key={item.box_order_number}>
                  <td className='box_order_number'>{item.box_order_number}</td>
                  <td>{item.id_order}</td>
                  <td>{item.cd_shift}</td>
                  <td>{moment(item.ts_value).format('HH:mm:ss DD/MM/YYYY')}</td>
                  <td>
                    <Tooltip text={'OK'}>
                      <Status color='#318F29' />
                    </Tooltip>
                    
                  </td>
                </tr>
              ))
            }
          </tbody>

        </table>
      </Container>
      {(missing?.length > 0) &&
        <Container size={30}>
          <p>Caixas não encontradas</p>
          <table>

            <thead>
              <tr> <td>Número</td> </tr>
            </thead>

            <tbody>
            {
              showMissingBoxes && showMissingBoxes.map((item) => (
                <tr key={item}> <td>{item}</td> </tr>
              ))
            }
        
            </tbody>

          </table>
        </Container>}

    </HistoryContainer>
  );
}

export default History;