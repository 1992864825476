import moment from 'moment';
import { NotificationError, NotificationWarning } from './toast';

/**
 * @function
 * @description Return capitalized string
 * @param {String} s
 * 
 */
export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * @function
 * @description Return if a number is invalid
 * @param {*} number
 */
export function isInvalidNumber(number) {
  return (
    isNaN(number) ||
    !isFinite(number) ||
    number === Infinity ||
    number === 'Infinity' ||
    number === -Infinity ||
    number === '-Infinity' ||
    number === NaN ||
    number === 'NaN' ||
    number === '∞' ||
    number === '-∞' ||
    number === undefined ||
    number === 'undefined' ||
    number === null ||
    number === 'null'
  );
};

export const fixNumber = (number, float, locale = 'pt-br') => {
  if (isInvalidNumber(number)) return number;
  if (float >= 0) {
    return number.toLocaleString(locale, {
      maximumFractionDigits: float,
      minimumFractionDigits: 0
    });
  }
  return number;
};

export function convertToDaysHoursNoSeconds(value, time) {
  //if (isInvalidNumber(value)) return '';
  let durationInSeconds = moment.duration(value, time);
  if (durationInSeconds.days() > 0) {
    return durationInSeconds.days() + 'd ' + moment.utc(durationInSeconds.valueOf()).format('HH:mm');
  }
  return moment.utc(durationInSeconds.valueOf()).format('HH:mm');
};

export function getTotalElapsedTime(value, time) {
  let durationInSeconds = moment.duration(value, time);
  let total = '';
  if (durationInSeconds.days() > 0) total = durationInSeconds.days() + 'd ';
  if (durationInSeconds.hours() > 0) total += durationInSeconds.hours() + 'h ';
  if (durationInSeconds.minutes() > 0) total += durationInSeconds.minutes() + 'm ';
  return total;

}

/**
 * @function
 * @description Return a percent of a determined time
 * @param {number} elapsedTime
 * @param {number} downtime
 */
export function getPercentHour(elapsedTime, downtime) {
  if (elapsedTime <= 60 || downtime === 0) return '0';
  let elapsedMinutes = Math.round(elapsedTime / 60);

  let percent = downtime * 100 / elapsedMinutes;
  return percent.toFixed(1);
};

/**
 * @function
 * @description Return an array of missing numbers
 * @param {Array<Number>} boxes 
 * 
 */
export function getMissingBoxes(boxes) {
  let arrA = boxes;
  let arrB = [];
  if (arrA) {
    arrA.sort((a, b) => b - a);
    for (let i = 0; i < arrA[0]; i++) {
      arrB.push(i + 1);
    }
  }
  let difference = arrB.filter((x) => !arrA.includes(x));
  return difference;
}

/**
 * @function
 * @description Return buffered boxes
 * @returns {Array<[]> | Boolean}
 */
export function getStorageBoxes() {
  let storageBoxes = localStorage.getItem('@barcode-scanner:buffer');

  if (storageBoxes) {
    storageBoxes = JSON.parse(storageBoxes);
    return storageBoxes;
  }
  return false;
}

/**
 * @description Return which character should be the separator Eg: 'ç' or ';'
 * @param {String} barcode 
 * @returns {String}
 */
export function barcodeSeparator(barcode) {
  let separator = ';';
  if (barcode?.includes('ç')) separator = 'ç';

  return separator;
}

/**
 * 
 * @description Return if a tag is valid
 * @example let tag = validateTag(barcode, boxes, isOffline, currentOrder);
 * if (tag) {
 *  window.print();
 * }
 * @param {String} barcode 
 * @param {Array<[]>} boxes 
 * @param {Boolean} isOffline 
 * @param {Array<[]>} order 
 * @returns {Boolean}
 */
export function validateTag(barcode, boxes, isOffline, order) {
  //barcode = barcode.replace(/(\r\n|\n|\r)/gm, '');
  const separator = barcodeSeparator(barcode);
  const barcodeSplited = barcode.split(separator);
  if (barcodeSplited.length) {
    let op = Number(barcodeSplited[0]);
    let number = Number(barcodeSplited[1]);
    let qty = Number(barcodeSplited[2]);
    let newBoxes = [...boxes];
    let storageBoxes = getStorageBoxes();
    if (barcodeSplited.length < 3) {
      NotificationWarning('Etiqueta inválida');
      return false;
    }
    if (isInvalidNumber(number) || isInvalidNumber(qty)) {
      NotificationWarning('Dados incorretos! Revise a etiqueta.');
      return false;
    }
    
    try {
      if (isOffline && storageBoxes) {
        for (let i in storageBoxes) {
          let boxArrayData = storageBoxes[i].barcode.split(separator);
          newBoxes.push({ box_order_number: Number(boxArrayData[1]), id_order: Number(boxArrayData[0]) });
        }
      }
      let isSameBox = newBoxes.find((item) => Number(item.box_order_number) === number && Number(op) === order[0].id_order);

      if (isSameBox) {
        NotificationError('Erro! caixa duplicada.');
        return false;
      }
        
    } catch (error) {
      console.error(error);
    }
    if (op === 0 || qty === 0) {
      NotificationWarning('Etiqueta inválida');
      return false;
    } else {
      if (order.length) {
        if (barcodeSplited[0] != order[0].id_order) {
          NotificationWarning('Ordem de produção incorreta!');
          return false;
        } else {
          return true;
        }
      }
    }
  }
  return false;
}

/**
 * 
 * @description Return an object that describe missing boxes
 * @param {String} barcode 
 * @param {Number} lastBox 
 * @returns 
 */
export function isMissingBox(barcode, lastBox) {
  const separator = barcodeSeparator(barcode);
  const barcodeSplited = barcode.split(separator);
  let number = 0;
  let nextBox = 0;
  if (barcodeSplited?.length) {
    number = Number(barcodeSplited[1]);
    nextBox = Number(lastBox) + 1;

    if (number !== nextBox) {
      return {
        isMissingBox: true,
        currentBox: number,
        nextBox    
      };
    }
  }

  return null;

}