import { getState, setState } from '../utils/storage';
let contextPersist = getState();
export const INITIAL_STATE = contextPersist || {
  currentOrder: [],
  equipment: {},
  isOffline: false,
  userName: '',
  id_enterprise: null
};

export const mainReducer = (state = INITIAL_STATE, action = {}) => {

  switch (action.type) {

  case 'CURRENT_ORDER':
    state = {
      ...state,
      ...{ currentOrder: action.payload }
    };
    setState(state);
    break;

  case 'USER_INFO':
    state = {
      ...state,
      ...{ userName: action.payload.name, id_enterprise: action.payload.id }
    };
    setState(state);
    break;
    
  case 'BOX_BUFFER':
    state = {
      ...state,
      ...{ buffer: action.payload }
    };
    setState(state);
    break;

  case 'CURRENT_EQUIPMENT':
    state = {
      ...state,
      ...{ equipment: action.payload }
    };
    setState(state);
    break;

  case 'NETWORK_STATUS':
    state = {
      ...state,
      ...{ isOffline: action.payload }
    };
    setState(state);
    break;

  default:
    break;
  }

  return state;
};
