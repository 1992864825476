import styled from 'styled-components';
import { DEVICE } from '../../utils/deviceSize';

export const Container = styled.div`
  width: 90%;
  margin: 30px auto;

  @media ${DEVICE.laptop} {
    width: 70%;
  }

`;

export const FlexContainer = styled.div`
  width: 100%;
  height: ${(props) => props.size}px;
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BoxContainer = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const HistoryContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;
